import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyD2vWqE5YpOpVELR9aiUr9lZdVdFPJGgTg",
  authDomain: "abate-financial-main.firebaseapp.com",
  projectId: "abate-financial-main",
  storageBucket: "af-receipts-input",
  messagingSenderId: "1011323234790",
  appId: "1:1011323234790:web:052bc5ee05ca3c2d8e0e27",
  measurementId: "G-PW2VD8Q5ZX"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)



export { app, auth };
